export default () => ({
  currentPage: null,
  perPage: null,
  totalRows: null,
  campaignList: [],
  admList: [],
  isBusy: false,
  filterParams: {},
  sortBy: '-idx',
  isLoading: false,
  errorReasonModal: {
    isVisible: false,
    errorReason: null,
  },
})
