import {
  computed,
} from "@vue/composition-api"
import useToast from "@/utils/toast"
import {
  replaceRouterQuery,
} from "@/utils/syncUrl"
import {
  values,
} from "@/utils/value"

import state from "../state"
import api from "../api"

export default () => {
  const { makeToast } = useToast()

  const searchParams = computed(() => ({
    page: state.currentPage,
    size: state.perPage,
    total: state.totalRows,
    ...state.filterParams,
  }))

  const categoryOptions = [
    {
      label: "캠페인 제목",
      paramName: "title",
    },
    {
      label: "IDX",
      paramName: "idx",
      format: "number",
    },
    {
      label: "캠페인 브랜드명",
      paramName: "brand_name",
    },
    {
      label: "마케터 이름",
      paramName: "adv_name",
    },
    {
      label: "마케터 휴대번호",
      paramName: "adv_phone",
    },
    {
      label: "마케터 이메일",
      paramName: "adv_email",
    },
    {
      label: "마케터 회사명",
      paramName: "adv_company",
    },
    {
      label: "마케터 IDX",
      paramName: "adv_idx",
      format: "number",
    },
    {
      label: "단계",
      paramName: "reduced_step",
      format: "checkbox",
      options: [
        { text: "모집", value: "RECRUIT" },
        { text: "가이드배정", value: "GUIDE" },
        { text: "상품배송", value: "DELIVERY" },
        { text: "원고검수", value: "PRE_POSTING" },
        { text: "포스팅인증", value: "POSTING_RATING" },
        { text: "통계확인", value: "STAT_ESTIMATE" },
        { text: "진행완료", value: "PROGRESSED" },
        { text: "보류", value: "PENDING" },
        { text: "모집중단", value: "STOP" },
      ],
    },
    {
      label: "상시여부",
      paramName: "is_endless",
      format: "checkbox",
      options: [
        { text: "상시", value: true },
        { text: "비상시", value: false },
      ],
    },
    {
      label: "상태",
      paramName: "state",
      format: "checkbox",
      options: [
        { text: "활성", value: "ACTIVE" },
        { text: "삭제", value: "INACTIVE" },
        { text: "작성중", value: "WRITING" },
        { text: "숨김", value: "HIDDEN" },
      ],
    },
    {
      label: "등록일시",
      paramName: "registered_at",
      format: "date",
    },
    {
      label: "시작일시",
      paramName: "start_at",
      format: "date",
    },
    {
      label: "캠페인 타입",
      paramName: "type",
      format: "checkbox",
      options: [
        { text: "인플루언서", value: "COMPT" },
        { text: "단순모집", value: "SIMPLE_RECRUIT" },
        { text: "구매리뷰", value: "PURCHASE_REVIEW" },
      ],
    },
    {
      label: "SNS채널",
      paramName: "sns_channel",
      format: "checkbox",
      options: [
        { text: "인스타", value: "INSTAGRAM" },
        { text: "블로그", value: "BLOG" },
        { text: "릴스", value: "INSTAGRAM_REELS" },
        { text: "유튜브", value: "YOUTUBE" },
        { text: "틱톡", value: "TIKTOK" },
      ],
    },
  ]

  const searchList = () => {
    state.isBusy = true
    return api.searchList({
      ...searchParams.value,
      sort_by: state.sortBy,
    }).then(response => {
      const resData = response.data
      state.campaignList = resData.data
      state.totalRows = resData.page_info.total_count
      state.filterParams = {
        ...state.filterParams,
        total: resData.page_info.total_count,
      }
    }).catch(() => {
      makeToast("danger", "목록을 가져오는데 실패 했습니다")
    }).finally(() => {
      state.isBusy = false
    })
  }

  const search = params => {
    state.currentPage = 1
    state.filterParams = {
      ...params,
    }
    replaceRouterQuery(searchParams.value)
    searchList()
  }

  const tableColumns = [
    { key: "idx", sortable: true },
    { key: "type", label: "캠페인 타입" },
    { key: "adv_name", label: "마케터" },
    { key: "title", label: "제목" },
    { key: "sns_channel", label: "SNS" },
    { key: "apply", label: "선정/신청" },
    { key: "is_endless", label: "상시여부" },
    { key: "registered_at", label: "등록일시", sortable: true },
    { key: "start_at", label: "시작일시" },
    { key: "reduced_step", label: "단계" },
    { key: "state", label: "상태" },
  ]

  const changePage = params => {
    state.currentPage = params.page
    searchList()
  }

  const changeSort = ctx => {
    state.sortBy = ctx.sortDesc ? ctx.sortBy : `-${ctx.sortBy}`
    searchList()
  }

  return {
    categoryOptions,
    tableColumns,
    searchList,
    search,
    changePage,
    changeSort,
  }
}
