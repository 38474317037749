<template>
  <div style="width: 40px;">
    <BAvatarGroup
      v-if="!!snsChannel"
      size="32px"
    >
      <BAvatar
        v-if="snsChannel.includes('INSTAGRAM')"
        rounded
        variant="warning"
        class="pull-up"
      >
        <span>INS</span>
      </BAvatar>
      <BAvatar
        v-if="snsChannel.includes('BLOG')"
        rounded
        variant="success"
        class="pull-up"
      >
        <span>BLG</span>
      </BAvatar>
      <BAvatar
        v-if="snsChannel.includes('INSTAGRAM_REELS')"
        rounded
        variant="warning"
        class="pull-up"
      >
        <span>RLS</span>
      </BAvatar>
      <BAvatar
        v-if="snsChannel.includes('YOUTUBE')"
        rounded
        variant="danger"
        class="pull-up"
      >
        <span>YTB</span>
      </BAvatar>
      <BAvatar
        v-if="snsChannel.includes('TIKTOK')"
        rounded
        variant="dark"
        class="pull-up"
      >
        <span>TTK</span>
      </BAvatar>
    </BAvatarGroup>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BAvatar,
  BAvatarGroup,
} from "bootstrap-vue"

export default defineComponent({
  components: {
    BAvatar,
    BAvatarGroup,
  },
  props: {
    snsChannel: [String, Array],
  },
  setup() {

  },
})
</script>
