<template>
  <div>
    <TagbyFilterV2
      :initialParams="state.filterParams"
      :categoryOptions="categoryOptions"
      :leftWidth="3"
      @search="search"
    />
    <TagbyList
      v-model="state.currentPage"
      :items="getters.items"
      :fields="tableColumns"
      :busy="state.isBusy"
      :current-page="state.currentPage"
      :total-rows="state.totalRows"
      :per-page="state.perPage"
      @page-change="changePage"
      @sort-changed="changeSort"
    >
      <template #cell(idx)="data">
        <BLink
          :to="{ name: isHiddenLink ? 'campaign-compt-hidden-detail' : 'campaign-compt-active-detail', params: { idx: data.value } }"
          class="font-weight-bold d-block text-nowrap"
        >
          {{ data.value }}
        </BLink>
      </template>

      <template #cell(type)="data">
        {{ resolveType(data.value) }}
      </template>

      <template #cell(adv_name)="data">
        <div
          v-if="data.item.adv_name != null"
          vertical-align="center"
        >
          <div>
            {{ data.item.adv_name }}
          </div>
          <small class="text-muted">{{ data.item.adv_company_name }}</small>
        </div>
      </template>

      <template #cell(title)="data">
        <div vertical-align="center">
          <div>
            {{ data.item.title }}
          </div>
          <small class="text-muted">{{ data.item.brand_name }}</small>
        </div>
      </template>

      <template #cell(sns_channel)="data">
        <ChannelAvatar :snsChannel="data.value" />
      </template>

      <template #cell(apply)="data">
        <span>
          {{ data.item.selected_count }} / {{ data.item.apply_count }}
        </span>
      </template>

      <template #cell(registered_at)="data">
        <TagbyDateColumn :value="data.value" />
      </template>

      <template #cell(start_at)="data">
        <TagbyDateColumn :value="data.value" />
      </template>

      <template #cell(state)="data">
        <div
          style="width: 50px"
          :class="resolveStateVariant(data.value)"
        >
          {{ resolveState(data.value) }}
        </div>
      </template>

      <template #cell(reduced_step)="data">
        <div
          style="width: 50px"
          :class="resolveStepVariant(data.item)"
        >
          {{ resolveReducedStep(data.item) }}
        </div>
      </template>

      <template #cell(is_endless)="data">
        {{ data.value ? '상시' : '비상시' }}
      </template>

    </TagbyList>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from "@vue/composition-api"
import {
  BLink,
  BButton,
  BAvatarGroup,
  BBadge,
  BAvatar,
} from "bootstrap-vue"
import useInitialize from "./hooks/useInitialize"
import useItems from "./hooks/useItems"
import useState from "./hooks/useState"
import TagbyFilterV2 from "@/components/TagbyFilterV2.vue"
import TagbyList from "@/components/TagbyList.vue"
import TagbyDateColumn from "@/components/TagbyDateColumn.vue"
import TagbySnsAvatarV2 from "@/components/TagbySnsAvatarV2.vue"
import state from "./state"
import getters from "./getters"
import ChannelAvatar from "./components/ChannelAvatar.vue"

export default defineComponent({
  components: {
    BLink,
    BButton,
    BAvatar,
    BAvatarGroup,
    BBadge,
    TagbyFilterV2,
    TagbyList,
    TagbyDateColumn,
    TagbySnsAvatarV2,
    ChannelAvatar,
  },
  setup() {
    const {
      resetState,
    } = useState()
    resetState()

    const {
      initialize,
    } = useInitialize()
    initialize()

    const {
      categoryOptions,
      hasRequestTime,
      tableColumns,
      search,
      searchList,
      changePage,
      changeSort,
    } = useItems()
    searchList()

    const resolveType = value => {
      if (value === "COMPT") {
        return "인플루언서"
      }
      if (value === "SIMPLE_RECRUIT") {
        return "단순모집"
      }
      if (value === "PURCHASE_REVIEW") {
        return "구매리뷰"
      }
      return ""
    }

    const resolveState = value => {
      if (value === "ACTIVE") {
        return "활성"
      }
      if (value === "INACTIVE") {
        return "삭제"
      }
      if (value === "WRITING") {
        return "작성중"
      }
      if (value === "HIDDEN") {
        return "숨김"
      }
      return ""
    }

    const reduceStep = (step, manageStep) => {
      if (step === "PROGRESSED") {
        return "PROGRESSED"
      }
      if (step === "STOP") {
        return "STOP"
      }
      if (step === "PENDING") {
        return "PENDING"
      }
      if (manageStep == null || manageStep === "SELECT") {
        return "RECRUIT"
      }
      if (manageStep === "GUIDE") {
        return "GUIDE"
      }
      if (manageStep === "DELIVERY") {
        return "DELIVERY"
      }
      if (manageStep === "PRE_POSTING") {
        return "PRE_POSTING"
      }
      if (manageStep === "POSTING_RATING") {
        return "POSTING_RATING"
      }
      if (manageStep === "STAT_ESTIMATE") {
        return "STAT_ESTIMATE"
      }
      return "NOT_MATCHED"
    }

    const resolveReducedStep = item => {
      const reducedStep = reduceStep(item.step, item.manage_step)
      if (reducedStep === "RECRUIT") {
        return "모집"
      }
      if (reducedStep === "GUIDE") {
        return "가이드"
      }
      if (reducedStep === "DELIVERY") {
        return "배송"
      }
      if (reducedStep === "PRE_POSTING") {
        return "원고검수"
      }
      if (reducedStep === "POSTING_RATING") {
        return "포스팅"
      }
      if (reducedStep === "STAT_ESTIMATE") {
        return "통계"
      }
      if (reducedStep === "PROGRESSED") {
        return "진행완료"
      }
      if (reducedStep === "PENDING") {
        return "보류"
      }
      if (reducedStep === "STOP") {
        return "모집중단"
      }
      return "NOT_MATCHED(개발자문의필요)"
    }

    const resolveStepVariant = item => {
      const value = reduceStep(item.step, item.manage_step)
      if (["STOP", "PENDING"].includes(value)) {
        return "text-danger"
      }
      if (["PROGRESSED"].includes(value)) {
        return "text-info"
      }
      return "text-success"
    }

    const resolveStateVariant = value => {
      if (value === "ACTIVE") {
        return "text-success"
      }
      if (value === "WRITING") {
        return "text-warning"
      }
      if (value === "INACTIVE") {
        return "text-danger"
      }
      return null
    }

    const isHiddenLink = ref(false)

    onMounted(() => {
      window.addEventListener("keydown", e => {
        if (e.code === "KeyA") {
          isHiddenLink.value = true
        }
      })

      window.addEventListener("keyup", e => {
        if (e.code === "KeyA") {
          isHiddenLink.value = false
        }
      })
    })

    return {
      state,
      getters,
      categoryOptions,
      hasRequestTime,
      tableColumns,
      isHiddenLink,
      search,
      searchList,
      changePage,
      changeSort,
      resolveType,
      resolveState,
      resolveReducedStep,
      resolveStepVariant,
      resolveStateVariant,
    }
  },
})
</script>
